export function videoDreambroker() {
  if (jQuery(".video__dreambroker").length === 0) {
    return;
  }

  jQuery(".video__dreambroker").each(function(index, element) {
    let video = document.createElement("video");
    let src = jQuery(element).data("embed");

    try {
      // Parse the Dreambroker video URL
      const parsedUrl = new URL(src);
      const searchParams = parsedUrl.searchParams;

      const loop = searchParams.get("loop");
      const quality = searchParams.get("quality");
      const autoplay = searchParams.get("autoplay");
      const lang = searchParams.get("lang") || 'da'; // Default to 'da' if no lang parameter is present
      const baseUrl = `${parsedUrl.origin}${parsedUrl.pathname}`;

      // Set video attributes
      video.setAttribute("tabindex", "0");
      video.setAttribute("aria-roledescription", "video");

      // Ensure controls are available
      video.setAttribute("controls", "");  // Ensure the controls appear
      if (autoplay === "true") {
        video.setAttribute('autoplay', "");
      }

      video.setAttribute('loop', loop === "true" ? 'true' : 'false');
      video.setAttribute('preload', 'none'); // As per the iframe example

      // Set the video poster (thumbnail image)
      video.poster = `${baseUrl}/get/poster?etag=1716383491000`;

      // Set the video sources
      video.src = `${baseUrl}/get/normal.mp4?lang=${lang}`;
      
      // Add additional sources for different quality levels (fullhd, hd, etc.)
      const sourceFullHd = document.createElement('source');
      sourceFullHd.setAttribute('src', `${baseUrl}/get/fullhd.mp4`);
      sourceFullHd.setAttribute('type', 'video/mp4');
      sourceFullHd.setAttribute('data-fullhd', `${baseUrl}/get/fullhd.mp4`);
      video.appendChild(sourceFullHd);

      const sourceHd = document.createElement('source');
      sourceHd.setAttribute('src', `${baseUrl}/get/hd.mp4`);
      sourceHd.setAttribute('type', 'video/mp4');
      sourceHd.setAttribute('data-hd', `${baseUrl}/get/hd.mp4`);
      video.appendChild(sourceHd);

      const sourceNormal = document.createElement('source');
      sourceNormal.setAttribute('src', `${baseUrl}/get/normal.mp4`);
      sourceNormal.setAttribute('type', 'video/mp4');
      sourceNormal.setAttribute('data-normal', `${baseUrl}/get/normal.mp4`);
      video.appendChild(sourceNormal);

      const sourceMobile = document.createElement('source');
      sourceMobile.setAttribute('src', `${baseUrl}/get/mobile.mp4`);
      sourceMobile.setAttribute('type', 'video/mp4');
      sourceMobile.setAttribute('data-mobile', `${baseUrl}/get/mobile.mp4`);
      video.appendChild(sourceMobile);

      // Create and add the subtitle track element
      const track = document.createElement('track');
      track.setAttribute('kind', 'subtitles');
      track.setAttribute('label', 'Dansk'); // Set the label as 'Dansk' (or based on lang)
      track.setAttribute('src', `${baseUrl}/subtitle/get/${lang}.vtt`);  // Dynamic subtitle URL
      track.setAttribute('srclang', lang);  // Language code (e.g., 'da' for Danish)
      track.setAttribute('default', 'true');  // Make this subtitle track the default
      video.appendChild(track);  

      // Append the video element to the container without removing the overlay
      jQuery(element).append(video);

    } catch (e) {
      console.error("Error parsing Dreambroker URL: ", e);
    }
  });
}

export default videoDreambroker;
