export function leftAlignedMegamenu() {
  // if megamenu
  if(document.querySelector('#headerLeftAligned') && document.querySelector('.list-item-left-aligned-toggle')){

		const toggleElements = [...document.querySelectorAll('.list-item-left-aligned-toggle')];


			const isCollapsed = document.querySelector('.collapsed-menu');
	
			toggleElements.forEach(item => {
	
			//toggle function for desktop
			if(window.innerWidth > 768 && !isCollapsed){
				item.addEventListener('click', event => {
					if(true){
						event.preventDefault()
						let isVisible = item.nextElementSibling.classList.contains('visible');
						
						let activeMenus = item.closest('ul').querySelectorAll('.visible')
						if(activeMenus[0] != undefined){
							activeMenus.forEach(item => {
								item.classList.remove('visible')
								item.previousElementSibling.setAttribute('aria-expanded', 'false')
								item.previousElementSibling.classList.remove('active')
							})
						}
						if(isVisible){
							item.nextElementSibling.classList.remove('visible');
							item.setAttribute('aria-expanded', 'false')
							item.classList.remove('active')
						} else if (!isVisible) {
							item.nextElementSibling.classList.add('visible');
							item.setAttribute('aria-expanded', true)          
							item.classList.add('active')
						}
					} else {
						return
					}
				})
	
				//toggle function for mobile
				} else if (window.innerWidth < 769 || isCollapsed){
					item.addEventListener('click', () => {
						item.nextElementSibling.classList.toggle('visible')
						if(item.getAttribute('aria-expanded') == 'false'){
							item.setAttribute('aria-expanded', true)
							item.querySelector('.list-item-megamenu-toggle-icon').style.transform = 'translateY(0%) rotate(315deg)'
						} else {
							item.setAttribute('aria-expanded', 'false')
							item.querySelector('.list-item-megamenu-toggle-icon').style.transform = 'translateY(-50%) rotate(135deg)'
						}
					})
				}
			})
	
			//hover intent for desktop
			if(window.innerWidth > 768 && !isCollapsed){
				// Store a reference to the timeout
				let hoverIntentTimeout;
	
				// Function to be executed when hover intent is triggered
				function handleHoverIntent(element) {
					listElements.forEach(element => {
						element.classList.remove('visible')
					})
					element.classList.add("visible");
				}
	
				// Function to be executed when hover intent is not triggered
				function handleHoverOut(element) {
					hoverIntentTimeout = setTimeout(() => {
						listElements.forEach(element => {
							element.classList.remove('visible')
						})
						//element.classList.remove("visible");
					}, 300); // Delay removal in milliseconds
				}
	
				// Add event listeners to all list elements
				const listElements = document.querySelectorAll(".sidemenu-list-item");

				

				listElements.forEach(element => {
					element.addEventListener("mouseenter", () => {
						//Only hover if not collapsed menu
						if(!document.querySelector('.collapsed-menu')){
							// Clear previous timeout (if any)
							clearTimeout(hoverIntentTimeout);
		
							// Set a new timeout to trigger hover intent after a delay
							hoverIntentTimeout = setTimeout(() => {
								handleHoverIntent(element);
							}, 300); // Delay in milliseconds
						}
					});
	
					element.addEventListener("mouseleave", () => {
						//Only hover effect if no collapsed menu
						if(!document.querySelector('.collapsed-menu')){
							// Clear the timeout when leaving the element
							clearTimeout(hoverIntentTimeout);
		
							// Handle hover out
							handleHoverOut(element);
						}
					});
				});
			}
	  
	
			
		}
	if(document.querySelector('#headerLeftAligned')){
		// ---- Scroll hide menu function ---- //
		const menuElement = document.querySelector('.header--left-aligned-container')

		//reference variable
		let oldScrollHeight;
		let height;

		function setVariables(){
			if(document.querySelector('.collapsed-menu')){
				height = menuElement.querySelector('.header--left-aligned-top-bar').offsetHeight
			} else {
				height = document.querySelector('.header--left-aligned-container').offsetHeight;	
			}
			oldScrollHeight = window.scrollY;
		}
		setVariables();

		window.addEventListener('resize', () => {
			setVariables()
		})

		window.addEventListener('scroll', () => {
			if(document.querySelector('#headerLeftAligned') && window.innerWidth > 768){
			let newScrollHeight = window.scrollY;
			//if scrolling up, menu appears
			if(newScrollHeight < oldScrollHeight && newScrollHeight > height){
				if(menuElement.classList.contains('suggestions-hidden')){
					menuElement.classList.remove('suggestions-hidden')
				}
				menuElement.style.position = 'fixed';
				menuElement.style.top = '0px';
		
			//if scrolling down and scrollheight is higher than menu height, menu disappears
			} else if (newScrollHeight > oldScrollHeight && newScrollHeight > height){
				if(!menuElement.classList.contains('suggestions-hidden')){
					menuElement.classList.add('suggestions-hidden')
				}
				menuElement.style.top = '-' + height + 'px'
				//setTimeout(() => menuElement.style.position = 'fixed', 200)
			//if scroll height is less than height of menu, fix it to the top
			} else if (newScrollHeight == 0){
				if(menuElement.classList.contains('suggestions-hidden')){
					menuElement.classList.remove('suggestions-hidden')
				}
				menuElement.style.top = '0px';
				menuElement.style.position = 'relative'

				//document.querySelector('main').style.paddingTop = document.querySelector('.header--left-aligned-top-bar').offsetHeight + 'px'
			}
		
			//set reference variable to current scroll height
			oldScrollHeight = newScrollHeight;
			  
			} else if (document.querySelector('#headerLeftAligned') && window.innerWidth < 769){
				if(menuElement.classList.contains('suggestions-hidden')){
					menuElement.classList.remove('suggestions-hidden')
				}
			  const menuElement = document.querySelector('.header--left-aligned-container')
			  document.querySelector('main').style.paddingTop = document.querySelector('.header--left-aligned-top-bar').offsetHeight + 'px'
			  menuElement.style.position = 'fixed'
			  menuElement.style.top = 0
			}

		})


		//if resizing from mobile to desktop, but menu is still collapsed, reset padding
		window.addEventListener('resize', () => {
			if(document.querySelector('#headerLeftAligned') && document.querySelector('#headerLeftAligned').classList.contains('collapsed-menu')){
				//if mobile
				if(window.innerWidth < 769){
					const menuElement = document.querySelector('.header--left-aligned')
					menuElement.style.position = 'relative'
					document.querySelector('main').style.paddingTop = 0
				}
				//if desktop
				else if (window.innerWidth > 769){
					const menuElement = document.querySelector('.header--left-aligned')
					menuElement.style.position = 'relative'
					document.querySelector('main').style.paddingTop = 0
					//document.querySelector('main').style.paddingTop = 0
				}
			} 
		})
	}
		
}

export default leftAlignedMegamenu;
