import cssVars from "css-vars-ponyfill";

export function watermark(selector) {
  // Check if the provided selector has any matching elements
  if (jQuery(selector).length === 0) {
    return;
  }

  // Select all watermark images within the specified selector
  const watermarks = jQuery(`${selector} .watermark img`);

  // Function to set the appropriate watermark based on the viewport size
  function updateWatermark(item) {
    const watermarkMobile = item.getAttribute("data-watermarkMobile");
    const watermarkDesktop = item.getAttribute("data-watermarkDesktop");

    // Only proceed if the mobile watermark exists
    if (watermarkMobile) {
      // Determine the current viewport size
      const isMobile = window.matchMedia("(max-width: 991px)").matches;

      // Set the watermark based on the viewport size
      if (isMobile && watermarkMobile) {
        item.setAttribute("src", watermarkMobile);
        item.setAttribute("data-src", watermarkMobile);
      } else if (watermarkDesktop) {
        item.setAttribute("src", watermarkDesktop);
        item.setAttribute("data-src", watermarkDesktop);
      }
    }
  }

  // Update watermarks on load and resize events
  jQuery(window).on("load resize", function () {
    watermarks.each(function (i, item) {
      updateWatermark(item);
    });
  });

  // Initial call to set watermarks correctly on page load
  watermarks.each(function (i, item) {
    updateWatermark(item);
  });
}

export default watermark;

// export function footer__watermark() {

// 	if (jQuery(".footer").length < 0) {
// 		return;
//   }

//   const watermark = jQuery(".footer .watermark img");

//   jQuery(watermark).each(function (i, item) {
//     var watermarkMobile = item.getAttribute("data-watermarkMobile");
//     var watermarkDesktop = item.getAttribute("data-watermarkDesktop");

//     // If has watermark mobile
//     if(item.hasAttribute("data-watermarkMobile")) {
//       jQuery(window).on("load resize", function () {

//         if ((typeof watermarkMobile !== 'undefined' && watermarkMobile !== false) && (typeof watermarkMobile !== 'undefined' && watermarkMobile !== false) || (typeof watermarkMobile !== 'undefined' && watermarkMobile !== false)) {
//           // Mobile (Tablet down)
//           if (window.matchMedia("(max-width: 991px)").matches) {
//             function setMobileWatermark() {
//               item.setAttribute("data-src", watermarkMobile);
//               item.setAttribute("src", watermarkMobile);
//             }
//             setMobileWatermark();
//           }

//           // Desktop (Tablet up)
//           if (window.matchMedia("(min-width: 991px)").matches) {
//             function setDesktopWatermark() {
//               item.setAttribute("data-src", watermarkDesktop);
//               item.setAttribute("src", watermarkDesktop);
//             }
//             setDesktopWatermark();
//           }
//         } else {
//           return;
//         }

//       });

//     } else {
//       return;
//     }

//   });

// }

// export default footer__watermark;
