export function headerMegamenuTabOrderMobile() {
  const megamenu = document.querySelector('.header__megamenu-extended');

  if (!megamenu) {
    return;
  }

  function reorderNav() {
    const navbarCollapse = document.querySelector('.navbar-collapse');
    const primaryContainer = document.querySelector('.navbar-nav__primary-container');
    const secondaryContainer = document.querySelector('.navbar-nav__secondary-container');
    
    // Check if viewport is mobile
    const isMobile = window.matchMedia('(max-width: 991px)').matches;

    if (isMobile) {
      // Ensure primary is first
      if (secondaryContainer.nextElementSibling == primaryContainer) {
        navbarCollapse.insertBefore(primaryContainer, secondaryContainer);
      }
    } else {
      // Restore original order on desktop
      if (primaryContainer.nextElementSibling == secondaryContainer) {
        navbarCollapse.insertBefore(secondaryContainer, primaryContainer);
      }
    }
  }

  // Run on page load and on window resize
  window.addEventListener('DOMContentLoaded', reorderNav);
  window.addEventListener('resize', reorderNav);

}

export default headerMegamenuTabOrderMobile;