import dataTabel from '../../modules/tabel/tabel.js';

export function accordion() {
	var accordion = jQuery(".js-accordion");

	if (accordion.length > 0) {
		accordion = (function () {
			// default settings
			var settings = {
				speed: 400,
				oneOpen: false,
			};

			// Site improve disable all accordions
			function isSiteImproveAccordionDisabled() {
				const disableValue = localStorage.getItem("siteImproveAccordionDisable");
				return disableValue === null || disableValue.toLowerCase() !== "false";
			}

			if (!isSiteImproveAccordionDisabled()) {
				return {
					init: function () {
						jQuery(".js-accordion-body").css("display", "block");
					},
				};
			}

			return {
				init: function ($settings) {
					jQuery(document).on("click", ".js-accordion-header", function (e) {
						e.preventDefault();

						if (jQuery(this).attr("aria-expanded") == "false") {
							var iframes = jQuery(this)
								.siblings(".accordion-body")
								.find("iframe");
							iframes.each(function (index, element) {
								element.style.maxHeight = "";
							});
						}

						accordion.toggle(jQuery(this));

						var queryString = window.location.search;
						if (
							jQuery(this).parent().hasClass("active") &&
							jQuery(this).parent().attr("id") != undefined
						) {
							window.history.replaceState(
								{},
								"",
								window.location.pathname + queryString + "#" + jQuery(this).parent().attr("id")
							);
						} else {
							window.history.replaceState({}, "", window.location.pathname + queryString);
						}
					});

					jQuery(document).on("click", ".js-accordion-toggle-all", function () {
						var $this = jQuery(this).parents(".accordion");
						var $accordionToggleBtn = $this.find(".js-accordion-toggle-all");
						var $textOpen = $accordionToggleBtn.attr("data-textOpen");
						var $textClose = $accordionToggleBtn.attr("data-textClose");

						if ($accordionToggleBtn.hasClass("active")) {
							$accordionToggleBtn.attr("aria-expanded", "false");
							$accordionToggleBtn.find("span").html($textOpen);
							$accordionToggleBtn.removeClass("active");
							$this.find(".js-accordion-header").attr("aria-expanded", "false");
							$this.find(".js-accordion-item").removeClass("active");
							$this.find(".js-accordion-body").stop().slideUp(400);
						} else {
							$accordionToggleBtn.attr("aria-expanded", "true");
							$accordionToggleBtn.find("span").html($textClose);
							$accordionToggleBtn.addClass("active");
							$this.find(".js-accordion-item").addClass("active");
							$this.find(".js-accordion-header").attr("aria-expanded", "true");
							$this.find(".js-accordion-body").stop().slideDown(400);
						}
					});

					$.extend(settings, $settings);

					jQuery(".js-accordion-item.active")
						.find("> .js-accordion-body")
						.show();
				},

				toggle: function ($this) {
					$this.attr("aria-expanded", "false");

					if (
						settings.oneOpen &&
						$this[0] !=
							$this.closest(".js-accordion")
								.find("> .js-accordion-item.active > .js-accordion-header")[0]
					) {
						$this.closest(".js-accordion")
							.find("> .js-accordion-item")
							.find(".js-accordion-body");
					}

					$this.closest(".js-accordion-item").toggleClass("active");
					$this.next().stop().slideToggle(settings.speed);

					if ($this.closest(".js-accordion-item").hasClass("active")) {
						$this.attr("aria-expanded", "true");

						// Initialize DataTable when the accordion item is opened
						var table = $this.closest(".js-accordion-item").find(".dataTable");
						if (table.length) {
							// Ensure the table is only initialized once
							if (!$.fn.dataTable.isDataTable(table)) {
								table.DataTable({
									responsive: true,
									pageLength: 10,
								});
							} else {
								// Trigger a resize of the DataTable columns
								table.DataTable().columns.adjust().draw();
							}
						}
					}
				},
			};
		})();

		jQuery(document).ready(function () {
			accordion.init({ speed: 300, oneOpen: true });
		});

		// Function to open accordion item and scroll to it
		function openAccordionAndScroll(accordionItem) {
			accordionItem
				.addClass("active")
				.find(".js-accordion-body")
				.addClass("active")
				.show();
			var accordionContainer = accordionItem.closest(".js-accordion");
			if (accordionContainer.length > 0) {
				accordionContainer.addClass("active");
				accordionContainer.find("iframe").css({ maxHeight: "none" });
			}
			jQuery("html, body").animate(
				{
					scrollTop: accordionItem.offset().top,
				},
				700
			);
		}

		// Open accordion based on URL fragment
		jQuery(window).on("load", function () {
			var urlHash = window.location.hash.substring(1);

			if (urlHash) {
				var targetAccordionItem = jQuery(".js-accordion-item#" + urlHash).not(".js-accordion-item-agenda");
				if (targetAccordionItem.length > 0) {
					openAccordionAndScroll(targetAccordionItem);
					// Trigger DataTable resize after opening accordion
					var table = targetAccordionItem.find(".dataTable");
					if (table.length && $.fn.dataTable.isDataTable(table)) {
						table.DataTable().columns.adjust().draw();
					}
				}
			}
		});

		// Handle anchor links that link to sections within the accordion
		jQuery('.article-anchor-menu a[href^="#"]').on("click", function (e) {
			e.preventDefault();
			var targetId = jQuery(this).attr("href").substring(1);
			var accordionSection = findAccordionSection(targetId);

			if (accordionSection) {
				// Open the accordion if found
				accordionSection.addClass("active");
				accordionSection.find(".js-accordion-body").addClass("active").show();
				// Scroll to the accordion
				jQuery("html,body").animate(
					{
						scrollTop: accordionSection.offset().top,
					},
					700
				);

				// Trigger DataTable resize after opening the accordion
				var table = accordionSection.find(".dataTable");
				if (table.length && $.fn.dataTable.isDataTable(table)) {
					table.DataTable().columns.adjust().draw();
				}
			}
		});
	}
}

function findAccordionSection(anchorId) {
	var accordionSection = null;
	jQuery(".js-accordion-item").each(function () {
		if (jQuery(this).find("#" + anchorId).length > 0) {
			accordionSection = jQuery(this);
			return false;
		}
	});
	return accordionSection;
}

export default accordion;
