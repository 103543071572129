/**
 * Data Table
 *
 */

export function dataTabel() {
	// Check if any table exists
	if (jQuery('.table').length > 0) {
		var lang_da_mod = {
			"sProcessing": "Henter...",
			"sLengthMenu": "Vis _MENU_ linjer",
			"sZeroRecords": "Ingen linjer matcher s&oslash;gningen",
			"sInfo": "Viser _START_ til _END_ af _TOTAL_ linjer",
			"sInfoEmpty": "Viser 0 til 0 af 0 linjer",
			"sInfoFiltered": "(filtreret fra _MAX_ linjer)",
			"sInfoPostFix": "",
			"sSearch": "S&oslash;g:",
			"sUrl": "",
			"oPaginate": {
				"sFirst": "F&oslash;rste",
				"sPrevious": "Forrige",
				"sNext": "N&aelig;ste",
				"sLast": "Sidste"
			}
		};

		// Iterate through each table
		jQuery('.table').each(function () {
			var table = jQuery(this);
			var id = table.attr("id"); // Ensure the table has an ID
			if (!id) {
				console.warn('Table is missing an ID and will not be initialized.'); 
				return; // Skip this table if no ID is found
			}

			var search = table.data('search');
			var searchfield = table.data('searchfield');

			// Configuration for tables with 'table--collapse' class
			if (table.hasClass('table--collapse')) {
				var entries = table.data('entries');
				var all = table.data('all');
				var lenghMenu = table.data('lengthmenu');
				var pagination = table.data('pagination');
				var info = table.data('info');

				lang_da_mod.lenghMenu = lenghMenu;
				lang_da_mod.pagination = pagination;
				lang_da_mod.info = info;
				lang_da_mod.searchfield = searchfield;
				lang_da_mod.sLengthMenu = '<select>' + '<option value="-1">' + all + '</option>' + '<option value="10" selected>10</option>' + '<option value="20">20</option>' + '<option value="30">30</option>' + '</select>';
				lang_da_mod.searchPlaceholder = "";
				lang_da_mod.sSearch = search;
				lang_da_mod.oPaginate.sNext = "";
				lang_da_mod.oPaginate.sPrevious = "";
				
				addTable(id, lang_da_mod);  // Call the addTable function for collapsed tables
			} else {
				// Configuration for other tables
				entries = table.data('entries');
				lenghMenu = table.data('lengthmenu');
				pagination = table.data('pagination');
				info = table.data('info');

				lang_da_mod.lenghMenu = lenghMenu;
				lang_da_mod.pagination = pagination;
				lang_da_mod.searchfield = searchfield;
				lang_da_mod.searchPlaceholder = "";
				lang_da_mod.sSearch = search;
				lang_da_mod.info = entries;
				lang_da_mod.oPaginate.sNext = "";
				lang_da_mod.oPaginate.sPrevious = "";

				addTableScrollable(id, lang_da_mod);  // Call the addTableScrollable function for non-collapsed tables
			}
		});
	}
}

// Initialize DataTable for regular tables
function addTable(id, lang_da_mod) {
	jQuery('#' + id).DataTable({
		ordering: true,
		retrieve: true,
		"info": lang_da_mod.info,
		"bFilter": lang_da_mod.searchfield,
		"bLengthChange": lang_da_mod.lenghMenu,
		"bPaginate": lang_da_mod.pagination,
		responsive: {
			details: {
				type: 'column'
			}
		},
		columnDefs: [{
			className: 'control',
			orderable: false,
			targets: 0
		}],
		"language": lang_da_mod
	});

	setTimeout(function () {
		jQuery('.table').css('opacity', 1);
		jQuery('.dataTables_wrapper').css('opacity', 1);

		jQuery('.dataTables_wrapper input').prop('required', true);
		jQuery('.dataTables_wrapper input').parent('label').attr('aria-label', 'Søg i tabel');
	}, 100);
}

// Initialize DataTable for scrollable tables (with fixed columns)
function addTableScrollable(id, lang_da_mod) {
	let resizeTimeout;
	jQuery(window).on("load resize", function () { // Mobile responsiveness
		var table = jQuery('#' + id); // Ensure we're using the correct table ID
		if (table.length === 0) {
			console.error('Table with id ' + id + ' not found.');
			return; // Skip if table doesn't exist
		}

		if (this.matchMedia("(max-width: 767px)").matches) {
			var tableScrollableTh = table.find('th');

			if (tableScrollableTh.length <= 3) {
				jQuery('.table-icon-scroll').css("display", "none");
			}

			// Debounced resize event
			clearTimeout(resizeTimeout);
			resizeTimeout = setTimeout(function () {
				jQuery(window).scroll(function () {
					var tableOffset = table.offset();
					if (!tableOffset) {
						console.error('Table offset is undefined.');
						return; // Skip if offset is not available
					}

					var top_of_element = tableOffset.top;
					var bottom_of_element = top_of_element + table.outerHeight();
					var bottom_of_screen = jQuery(window).scrollTop() + jQuery(window).innerHeight();
					var top_of_screen = jQuery(window).scrollTop();

					if ((bottom_of_screen > top_of_element) && (top_of_screen < bottom_of_element)) { 
						jQuery('.table-icon-scroll').css("opacity", 1);
					} else {
						jQuery('.table-icon-scroll').css("opacity", 0);
					}

					if ((bottom_of_screen > bottom_of_element) && (top_of_screen < bottom_of_element)) { 
						jQuery('.table-icon-scroll').css("opacity", 0);
					}
				});
			}, 150); // Adjust debounce time as needed
		}
	});

	jQuery('#' + id).DataTable({
		ordering: true,
		retrieve: true,
		"info": lang_da_mod.info,
		"bFilter": lang_da_mod.searchfield,
		"bLengthChange": lang_da_mod.lenghMenu,
		"bPaginate": lang_da_mod.pagination,
		columnDefs: [{
			className: 'control',
			orderable: false,
			targets: 0
		}],
		fixedColumns: true,
		"language": lang_da_mod,
		scrollX: true,
		scrollCollapse: true,
	});

	setTimeout(function () {
		jQuery('.table').css('opacity', 1);
		jQuery('.dataTables_wrapper').css('opacity', 1);

		jQuery('.dataTables_wrapper input').prop('required', true);
		jQuery('.dataTables_wrapper input').parent('label').attr('aria-label', 'Søg i tabel');
	}, 100);
}

export default dataTabel;
