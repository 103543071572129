/**
 * Service menu
 * Share by email
 */

export function serviceMenuShareByEmail() {
  // Check if the service menu exists before proceeding
  if (jQuery(".service-menu").length === 0) {
    return;
  }

  // Handle email share by link (mailto link)
  const emailShareButtons = document.querySelectorAll('.email-share-btn');

  if (emailShareButtons.length > 0) {
    emailShareButtons.forEach(button => {
      button.addEventListener("click", () => {
        // Access the data attributes
        const subject = button.dataset.emailSubject || ""; // No default subject
        const linkText = button.dataset.emailLinkText || ""; // No default link text
        const body = button.dataset.emailBody || ""; // No default body text

        const pageUrl = window.location.href;

        // Construct the email body
        const fullBody = `${body}${body ? "\n\n" : ""}${linkText ? `${linkText} ` : ""}${pageUrl}`;

        // Open the default email client with subject and body pre-filled
        window.location.href = `mailto:?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(fullBody)}`;
      });
    });
  }
}

export default serviceMenuShareByEmail;
